import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";

const ThankYouPage = () => (
  <Layout>
    <Seo title="Thank You" />
    <article className="article flow">
      <div className="hero-image-wrapper">
        <StaticImage
          className="hero-image"
          src="../images/main-home.jpg"
          width={637}
          height={637}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="a hand full of dirt"
        />
      </div>
      <div className="headline">
        <h1>Thank You</h1>
      </div>
      <div className="article-wrapper flow">
        <p>
          One of our local specialists will be in touch to start
          the&nbsp;conversation.
        </p>
      </div>
    </article>
  </Layout>
);

export default ThankYouPage;
